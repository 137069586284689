import { BaseResponse, Money } from '~/sdk/shared'
import { HasRequirements } from './productTypes'
import API from '~/sdk/client'

export interface Entity extends HasRequirements {
  type: 'FACTORING'
  enabled: boolean
  availableAmount: Money
  interest: number
  originalInterest: number
  formattedInterest: string
  formattedOriginalInterest: string
  feePerInvoice: Money
  feePerForeignInvoice: Money
  pendingAmount: Money
  termsUrl: string | null
  isActive: boolean
}

export async function get(): Promise<BaseResponse<Entity>> {
  return await API.get('internal/v2/company/products/factoring')
}
